// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/fonts/Amble-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Amble-Regular\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}", "",{"version":3,"sources":["webpack://./src/styles/_variables.scss","webpack://./src/container/SingleMovie/SingleMovie.scss"],"names":[],"mappings":"AAYA;EACC,4BAAA;EACA,4CAAA;ACVD","sourcesContent":["// Colors\n$color-primary: #ffffff; // White\n$color-primary-dark: rgba(242, 244, 246, 0.9); // Light gray\n$color-secondary: #79b8f3; // Light blue\n$color-secondary-light: #90dffe; // Light blue\n$color-tertiary: #8197a4; // Dark gray\n$color-button: #0f79af; // Dark blue\n$color-button-hover: #1399de; // Light blue\n$color-button-secondary: #425265; // Gray\n$color-background: #0f171e; // Dark blue-gray background color\n\n// Font Variables\n@font-face {\n\tfont-family: \"Amble-Regular\";\n\tsrc: url(../assets/fonts/Amble-Regular.ttf);\n}\n\n@import url(\"https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap\");\n$font-primary: \"Amble-Regular\", \"PT Sans\", Arial, sans-serif;\n\n// Portrait phones\n$breakpoint-x-small: 360px;\n\n// Landscape phones\n$breakpoint-small: 480px;\n\n// Tablets\n$breakpoint-medium: 768px;\n\n// Desktops\n$breakpoint-large: 992px;\n\n// Large desktops\n$breakpoint-x-large: 1200px;\n\n// Larger desktops\n$breakpoint-xx-large: 1400px;\n","@import url(\"https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap\");\n@font-face {\n  font-family: \"Amble-Regular\";\n  src: url(../assets/fonts/Amble-Regular.ttf);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
